<template>
  <div class="refund-page">
    <van-empty 
      description="官方频道暂未开放~"
    ></van-empty>
    <p class="intro">敬请期待</p>
  </div>

</template>

<script>
import { Empty } from 'vant'

export default {
  data() { 
    return {}
  },
  components: {
    [Empty.name]: Empty
  },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_var.scss';

.refund-page {
  padding-top: 40px;
  text-align: center;

  .intro {
    margin-top: 20px;
    font-size: 15px;
  }
}
</style>